<template>
  <v-container>
    <div id="companies" v-if="user && companies">
      <v-card class="elevation-0">
        <v-card-title>
          <v-row class="justify-space-between">
            <v-col cols="6">
              <v-text-field
                class="mr-6"
                v-model="search"
                append-icon="mdi-magnify"
                label="Søg"
                single-line
                hide-details
              ></v-text-field>
            </v-col>
            <v-col cols="4" class="mt-3 text-end">
              <v-btn class="primary" @click="createNew">Opret ny</v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-data-table
          class="table-cursor"
          :headers="headers"
          :items="companies"
          :search="search"
          :options="{ itemsPerPage: 1000 }"
          hide-default-footer
          @click:row="rowClick"
        ></v-data-table>
      </v-card>

      <v-dialog v-model="itemDialog" scrollable max-width="600px">
        <v-card>
          <v-card-title>{{ dialogHeader }}</v-card-title>
          <v-divider></v-divider>
          <v-card-text class="pt-4">
            <v-form v-model="formValid" ref="form" :disabled="!loaded">
              <v-container>
                <v-row>
                  <v-col cols="12" md="6"
                    ><v-text-field
                      dense
                      class="mt-6"
                      v-model="form.name"
                      :rules="[formRules.requiredRule]"
                      label="Navn"
                      required
                    ></v-text-field
                  ></v-col>
                </v-row>
              </v-container>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions class="py-4">
            <div class="d-flex justify-space-between" style="width: 100%">
              <div></div>
              <div>
                <v-btn
                  :disabled="!loaded"
                  class="primary--text"
                  text
                  @click="itemDialog = false"
                >
                  Annuller
                </v-btn>
                <v-btn
                  :disabled="!loaded"
                  v-if="dialogMode === 'new'"
                  class="primary ml-6"
                  @click="dialogConfirmCreate"
                >
                  Opret
                </v-btn>
                <v-btn
                  :disabled="!loaded"
                  v-if="dialogMode === 'edit'"
                  class="primary ml-6"
                  @click="dialogConfirmSave"
                >
                  Save
                </v-btn>
              </div>
            </div>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-container>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { required } from "../utils/FormRules";

export default {
  name: "Companies",
  data() {
    return {
      itemDialog: false,
      dialogMode: "edit",
      dialogEditId: null,
      search: "",
      headers: [{ text: "Navn", value: "name" }],
      defaultForm: {
        name: "",
      },
      form: {
        name: "",
      },
      formValid: false,
      formRules: {
        requiredRule: required,
      },
    };
  },
  mounted() {
    this.loadCompanies();
  },
  methods: {
    ...mapActions(["loadCompanies", "createCompany", "saveCompany"]),
    createNew() {
      if (this.$refs.form) {
        this.$refs.form.reset();
      }
      this.form = Object.assign({}, this.defaultForm);
      this.dialogEditId = null;
      this.dialogMode = "new";
      this.itemDialog = true;
    },
    dialogConfirmCreate() {
      this.createCompany(this.form);
      this.itemDialog = false;
    },
    dialogConfirmSave() {
      this.saveCompany({
        companyId: this.dialogEditId,
        company: this.form,
      });
      this.itemDialog = false;
    },
    rowClick(row) {
      this.form.name = row.name;
      this.dialogEditId = row._id;
      this.dialogMode = "edit";
      this.itemDialog = true;
    },
  },
  computed: {
    ...mapState(["loaded", "user", "token", "companies"]),
    dialogHeader() {
      return this.dialogMode == "new" ? "Opret bryggeri" : "Rediger bryggeri";
    },
  },
};
</script>
<style>
.table-cursor tbody tr:hover {
  cursor: pointer;
}
</style>
